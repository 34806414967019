import React, { useContext, useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import PageContext from '@context'
import get from 'lodash/get'
import ResourceCard from '@system/resource-card'
import { getSortedStories } from '@helpers'
import formatLocalizedLink from '@helpers/format-localized-link'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles((theme) => ({
  flexBoxContainer: { margin: '40px 0px', padding: '0px 8px' },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    width: 'calc(100% + 16px)',
    margin: '-8px',
  },
  storyContainer: {
    flexGrow: 0,
    padding: '8px',
    maxWidth: '33.333333%',
    flexBasis: '33.333333%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% ',
      flexBasis: '100%',
    },
  },
}))

const CustomerReferences = (props) => {
  const classes = useStyles(props.blok)
  const pageContext = useContext(PageContext)

  const {
    isReferencingAllPublishedCustomers,
    numberOfReferences,
    customerStories,
    sortBy,
    filters,
  } = props.blok

  const [filteredCustomerStories, setFilteredCustomerStories] = useState([])

  const filteredBroadensStories = []
  const filteredNarrowsStories = []

  const shouldSortCustomerStories =
    sortBy === 'alphabetical' ||
    sortBy === 'chronological' ||
    sortBy === 'reverse-chronological'

  let hasStoryMatched = false
  let hasStoryLogicMatched = false
  let isStoryPresentInFilteredStories = false

  const broadensResults = 'broadensResults'
  const narrowsResults = 'narrowsResults'

  const fetchCustomersFilterOptions = (filterData) => {
    const filteredData =
      !!filters &&
      filters.filter((filter) => filter.filterDataPath === filterData)
    const filtersFieldOption = get(filteredData, '[0].options.[0].fieldOptions')

    return filteredData.length > 0 ? filtersFieldOption : []
  }

  const getFormattedFilterOpions = (filtersName) =>
    filters
      ? fetchCustomersFilterOptions(filtersName)
      : filtersName === ''
      ? []
      : typeof filtersName === 'string'
      ? [filtersName]
      : filtersName

  const formattedCustomerStories = (stories) => {
    return stories
      .map((story) => ({
        ...story,
        ...story.content,
      }))
      .reverse()
  }

  const customerFirmTypeFilter = getFormattedFilterOpions('firmType')
  const customerCapabilitiesFilter = getFormattedFilterOpions('capabilities')
  const customerProductsFilter = getFormattedFilterOpions('products')
  const customerValueDriverFilter = getFormattedFilterOpions('valueDriver')

  const isCustomerVideoModule =
    get(filters, '[0].filterDataPath') === 'isCustomerVideoModule'

  const getFilteredResults = (logicType) =>
    filters.filter((filter) => {
      const hasFilterOptions = get(filter.options, '[0].fieldOptions')

      return (
        filter.filterResultsLogic === logicType &&
        (filter.component === 'booleanFilter' ||
          (hasFilterOptions && hasFilterOptions.length > 0))
      )
    })

  const filterNarrowsResults = getFilteredResults(narrowsResults)
  const filterBroadensResults = getFilteredResults(broadensResults)

  const fetchMultipleValueLogic = (value) => {
    const multiValueLogic =
      filters &&
      filters.some(
        (multiValueLogic) =>
          multiValueLogic.filterDataPath === value &&
          multiValueLogic.multipleValuesLogic === narrowsResults
      )

    return multiValueLogic ? narrowsResults : broadensResults
  }

  const fetchListOfMatchedResources = (
    filterOptions,
    storyOptions,
    filteredBroadensStories,
    multipleValuesLogic,
    story
  ) => {
    return multipleValuesLogic === narrowsResults
      ? filterOptions.forEach((filterOption, index) =>
          storyOptions &&
          storyOptions.length > 0 &&
          storyOptions.indexOf(filterOption) !== -1
            ? filterOptions.length - 1 === index &&
              !hasStoryMatched &&
              !isStoryPresentInFilteredStories &&
              story
              ? (filteredBroadensStories.push(story), (hasStoryMatched = true))
              : (hasStoryLogicMatched = true)
            : (isStoryPresentInFilteredStories = true)
        )
      : filterOptions.forEach(
          (filterOption) =>
            storyOptions &&
            storyOptions.length > 0 &&
            storyOptions.indexOf(filterOption) > -1 &&
            !hasStoryLogicMatched &&
            (story
              ? (filteredBroadensStories.push(story),
                (hasStoryLogicMatched = true))
              : (hasStoryLogicMatched = true))
        )
  }

  const filterMatchedResources = (
    filterResult,
    category,
    resourceCategory,
    resourceOption,
    filteredArray,
    story
  ) => {
    const hasMatched = hasFilterOptionMatched(filterResult, category)
    if (hasMatched) {
      const multipleValues = fetchMultipleValueLogic(category)
      fetchListOfMatchedResources(
        resourceCategory,
        resourceOption,
        filteredArray,
        multipleValues,
        story
      )
    }
  }

  const hasFilterOptionMatched = (filterResults, option) =>
    filterResults.some((filterResult) => filterResult.filterDataPath === option)

  const allCustomerStories = isReferencingAllPublishedCustomers
    ? pageContext.customerStories &&
      formattedCustomerStories(pageContext.customerStories)
    : customerStories && formattedCustomerStories(customerStories)

  const numberOfCustomerStories =
    numberOfReferences || (allCustomerStories && allCustomerStories.length)

  const fetchBroadensResult = (customerStories) => {
    customerStories.forEach((story) => {
      if (customerFirmTypeFilter && customerFirmTypeFilter.length > 0) {
        filterMatchedResources(
          filterBroadensResults,
          'firmType',
          customerFirmTypeFilter,
          story.firmType,
          filteredBroadensStories,
          story
        )
        isStoryPresentInFilteredStories = false
      }
      if (customerCapabilitiesFilter && customerCapabilitiesFilter.length > 0) {
        filterMatchedResources(
          filterBroadensResults,
          'capabilities',
          customerCapabilitiesFilter,
          story.capabilities,
          filteredBroadensStories,
          story
        )
        isStoryPresentInFilteredStories = false
      }
      if (customerProductsFilter && customerProductsFilter.length > 0) {
        filterMatchedResources(
          filterBroadensResults,
          'products',
          customerProductsFilter,
          story.productsUsed,
          filteredBroadensStories,
          story
        )
        isStoryPresentInFilteredStories = false
      }
      if (customerValueDriverFilter && customerValueDriverFilter.length > 0) {
        filterMatchedResources(
          filterBroadensResults,
          'valueDriver',
          customerValueDriverFilter,
          story.valueDriver,
          filteredBroadensStories,
          story
        )
        isStoryPresentInFilteredStories = false
      }

      if (!!isCustomerVideoModule) {
        story.wistiaVideoModule &&
          story.wistiaVideoModule.length > 0 &&
          (filteredBroadensStories.push(story), (hasStoryMatched = true))
      }

      hasStoryLogicMatched = false
      hasStoryMatched = false
    })
  }

  const fetchNarrowsResult = (customerStories) => {
    customerStories.forEach((story) => {
      if (customerFirmTypeFilter && customerFirmTypeFilter.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          'firmType',
          customerFirmTypeFilter,
          story.firmType,
          filteredNarrowsStories
        )
      }
      if (customerCapabilitiesFilter && customerCapabilitiesFilter.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          'capabilities',
          customerCapabilitiesFilter,
          story.capabilities,
          filteredNarrowsStories
        )
      }
      if (customerProductsFilter && customerProductsFilter.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          'products',
          customerProductsFilter,
          story.productsUsed,
          filteredNarrowsStories
        )
      }
      if (customerValueDriverFilter && customerValueDriverFilter.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          'valueDriver',
          customerValueDriverFilter,
          story.valueDriver,
          filteredNarrowsStories
        )
      }
      if (!!isCustomerVideoModule) {
        story.wistiaVideoModule &&
          story.wistiaVideoModule.length > 0 &&
          (hasStoryLogicMatched = true)
      }

      hasStoryLogicMatched && filteredNarrowsStories.push(story)
      hasStoryLogicMatched = false
    })
  }

  useEffect(() => {
    loadFilteredCustomers(allCustomerStories)
  }, [sortBy, pageContext.customerStories])

  const loadFilteredCustomers = (customerStories) => {
    if (filters && filters.length > 0) {
      filterBroadensResults &&
        filterBroadensResults.length > 0 &&
        fetchBroadensResult(customerStories)

      const filterNarrowsCustomers =
        filterBroadensResults && filterBroadensResults.length > 0
          ? filteredBroadensStories
          : customerStories

      filterNarrowsResults &&
        filterNarrowsResults.length > 0 &&
        filterNarrowsCustomers &&
        fetchNarrowsResult(filterNarrowsCustomers)
    }

    const getSortedCustomerStories = () => {
      if (filterNarrowsResults.length > 0 || filterBroadensResults.length > 0) {
        if (filterNarrowsResults.length > 0) {
          return filteredNarrowsStories.length > 0 && shouldSortCustomerStories
            ? getSortedStories(
                filteredNarrowsStories,
                sortBy,
                pageContext.customerStories
              )
            : filteredNarrowsStories
        } else {
          return shouldSortCustomerStories
            ? getSortedStories(
                filteredBroadensStories,
                sortBy,
                pageContext.customerStories
              )
            : filteredBroadensStories
        }
      } else {
        return getSortedStories(
          customerStories,
          sortBy,
          pageContext.customerStories
        )
      }
    }

    setFilteredCustomerStories(
      sortBy === 'reverse-chronological' || sortBy === 'none'
        ? getSortedCustomerStories().reverse()
        : getSortedCustomerStories()
    )
  }

  const surfaceRegionalStories = (a, b) => {
    return a.isLocalizedStory ? -1 : 1
  }

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.flexBoxContainer}>
        <Box className={classes.innerContainer}>
          {!!filteredCustomerStories &&
            filteredCustomerStories
              .sort(surfaceRegionalStories)
              .slice(0, numberOfCustomerStories)
              .map((story) => {
                const isVideoStory =
                  story.wistiaVideoModule?.length > 0 ||
                  story.body.filter(
                    (bodyBlok) => bodyBlok.component === 'wistiaVideoModule'
                  ).length > 0

                const stories = {
                  alignment: 'left',
                  description: story.subtitle,
                  image: story.image,
                  noDescMargin: false,
                  notVisibleOnMobile: false,
                  title: story.title,
                  resourceReference: true,
                  format: 'Customer Story',
                  link: formatLocalizedLink(story.full_slug),
                  textToHyperlink: isVideoStory ? 'Watch' : 'Read',
                }
                return (
                  <Box className={classes.storyContainer} key={story.id}>
                    <ResourceCard
                      blok={stories}
                      imgMaxWidth={imgMaxWidths.resourceCard}
                    />
                  </Box>
                )
              })}
        </Box>
      </Box>
    </SbEditable>
  )
}

export default CustomerReferences
